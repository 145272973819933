import React, { useEffect, useState } from "react";
import Login from "../../components/Login";
import Form from "./Form";
import Code from "./Code";
import Barcode from "./Barcode";
import Confirm from "./Confirm";
import { useNavigate } from "react-router";
import { addNotification } from "../../components/Notification";
import requestHandler from "../../actions/httpClient";
import LoaderScreen from "../../components/LoaderScreen";
import Logo from '../../components/Logo'
// import { io } from "socket.io-client";
import { socket } from "../../socket";

const SignIn = () => {

  // initializing the states and variables
  const navigate = useNavigate();
  const [scan, setScan] = useState(false);
  const [email, setEmail] = useState();
  const [activeIndex, setActiveIndex] = useState(0);
  const [signInSuccess, setSignInSuccess] = useState([]);
  const [signInLoading, setSignInLoading] = useState(false)
  const [captchaData, setCaptchaData] = useState([])
  const [reCaptcha, setReCaptcha] = useState('')
  const [captchaLoading, setCaptchaLoading] = useState(false)
  const [qrCodeData, setQrCodeData] = useState("");
  const [sessionId, setSessionId] = useState("");
  const [qrCodeLogin, setQrCodeLogin] = useState(false);
  const [showQrCode, setShowQrCode] = useState(true);
  const [count, setCount] = useState(0);

  // API's data
  let verify2FALoading = false
  let otpVerifyLoading = false

  // useEffect to check if signature/jwt_token is available in local storage or not.
  useEffect(() => {
    if (localStorage.getItem('signature') && localStorage.getItem('signature') !== ""
      && localStorage.getItem('jwt_token') && localStorage.getItem('jwt_token') !== "") {
      navigate('/');
    };
    captchaHandler();
    getQrCodeHandler();

    // return () => {
    //   socket.disconnect();
    // }
  }, []);

  useEffect(() => {
    setSignInLoading(true);
    if (sessionId !== "") {
      setSignInLoading(false);
      socket.on("connect", () => {
      });

      socket.on('loginWebhook_' + sessionId, function (result) {
        if (result[0].signature) {
          var signature = result[0].signature;
          signature = signature.replace('+', '_');
          signature = signature.replace('/', '-');
          window.location.href = result[0].url + '?sig=' + signature;
        }
      });
    }

    return () => {
      socket.disconnect();
    }
  }, [sessionId]);

  const captchaHandler = async () => {
    setCaptchaLoading(true)
    const data = {
      theme: localStorage.getItem('darkMode')
    }
    try {
      const captchPayload = await requestHandler("createCaptcha", 'post', data);
      setCaptchaData(captchPayload?.data)
      setCaptchaLoading(false)
    }
    catch (error) {
    }
  }
  // function to call the sign in API
  const loginHandler = async (email, password, cId) => {
    setSignInLoading(true)
    setEmail(email);
    try {
      let captchaData = {
        id: cId,
        code: reCaptcha
      };
      const verifyCaptchaPayload = await requestHandler("verifyCaptcha", 'post', captchaData);
      if (verifyCaptchaPayload.status === 200) {
        let data = {
          email: email,
          password: password
        };
        const signInPayload = await requestHandler("login", 'post', data);
        setSignInSuccess(signInPayload);
        if (signInPayload && signInPayload.status === 200) {
          setSignInLoading(false)
          setActiveIndex(1);
        }
        else if (signInPayload && signInPayload.status === 201) {
          setSignInLoading(false)
          setActiveIndex(2);
        }
        else if (signInPayload && signInPayload.status === 202) {
          setSignInLoading(false)
          setActiveIndex(3);
        }
        else if (signInPayload && signInPayload.status === 203) {
          setSignInLoading(false);
          navigate({ pathname: "/forgot-password", state: { email: email, code: 1, type: signInPayload?.data?.message?.type } });
        }
      }
    }
    catch (e) {
      await captchaHandler()
      setReCaptcha('')
      setSignInLoading(false)
      addNotification({
        title: "Error",
        message: e.data.message[0].msg,
        type: "danger"
      });
    };
  };

  // function to call the verify 2FA API
  const verify2FAHandler = async (firstField, secondField, thirdField, fourthField, fifthField, sixthField) => {
    const code = firstField + secondField + thirdField + fourthField + fifthField + sixthField;
    setSignInLoading(true)
    try {
      verify2FALoading = true
      let data = {
        email,
        code: code,
      };
      const verify2FAPayload = await requestHandler("verifyLogin", "post", data);
      setSignInLoading(false)
      // setVerify2FASuccess(verify2FAPayload);
      if (verify2FAPayload && verify2FAPayload.status === 200 && verify2FAPayload.data.data[(verify2FAPayload.data.data.length) - 1].role === process.env.REACT_APP_VALID_ROLE ||
        verify2FAPayload && verify2FAPayload.status === 200 && verify2FAPayload.data.data[(verify2FAPayload.data.data.length) - 1].role === process.env.REACT_APP_CORPORATE_ROLE) {
        if (verify2FAPayload.data.data[(verify2FAPayload.data.data.length) - 1].signature) {
          var signature = verify2FAPayload.data.data[(verify2FAPayload.data.data.length) - 1].signature;
          signature = signature.replace('+', '_');
          signature = signature.replace('/', '-');
          window.location.href = verify2FAPayload.data.data[(verify2FAPayload.data.data.length) - 1].url + '?sig=' + signature;
        }
        else {
          window.location.href = verify2FAPayload.data.data[(verify2FAPayload.data.data.length) - 1].url;
        }
      } else {
        addNotification({
          title: 'Error',
          message: 'You have not valid access',
          type: 'danger'
        });
      }
      navigate("/sign-in");
    }
    catch (e) {
      setSignInLoading(false)
      verify2FALoading = false
      addNotification({
        title: 'Error',
        message: e.data.message[0].msg,
        type: 'danger'
      });
    }
  };

  // function to call the otp verification API
  const codeScreenHandler = async (firstField, secondField, thirdField, fourthField, fifthField, sixthField) => {
    const code = firstField + secondField + thirdField + fourthField + fifthField + sixthField;
    try {
      setSignInLoading(true)
      otpVerifyLoading = true
      let data = {
        email_otp: code,
        email,
        type: signInSuccess.data.message.type,
      };
      // signInSuccess = [];
      const codeScreenPayload = await requestHandler("verify_otp", "post", data);
      setSignInLoading(false)
      setSignInSuccess(codeScreenPayload);
      if (codeScreenPayload && codeScreenPayload.status === 200) {
        setActiveIndex(1);
        // addNotification({
        //   title: 'Success',
        //   message: codeScreenPayload.data.message[0].msg,
        //   type: 'Success'
        // });
      }
      else if (codeScreenPayload && codeScreenPayload.status === 202) {
        setActiveIndex(3);
      };
    }
    catch (e) {
      setSignInLoading(false)

      otpVerifyLoading = false
      addNotification({
        title: 'Error',
        message: e.data.message[0].msg,
        type: 'danger'
      });
    }
  };

  const getQrCodeHandler = async () => {
    setSignInLoading(true);
    try {
      const getQrCodePayload = await requestHandler("generateQRLogin", "post");
      setSignInLoading(false);
      setQrCodeData(getQrCodePayload?.data?.data?.qrCode);
      setSessionId(getQrCodePayload?.data?.data?.sessionId);
    }
    catch (e) {
      setSignInLoading(false);
    };
  };

  return (
    <Login
      content="Don’t have an account?"
      linkText="Sign up for free"
      linkUrl="/sign-up"
    >
      <Logo />
      {signInLoading && <LoaderScreen />}
      {
        activeIndex === 0 && <Form
          onScan={() => setScan(true)}
          loginHandler={loginHandler}
          captcha={captchaData}
          reCaptcha={reCaptcha}
          setReCaptcha={setReCaptcha}
          captchaLoading={captchaLoading}
          qrCodeData={qrCodeData}
          sessionId={sessionId}
          setSessionId={setSessionId}
          getQrCodeHandler={getQrCodeHandler}
          qrCodeLogin={qrCodeLogin}
          setQrCodeLogin={setQrCodeLogin}
          showQrCode={showQrCode}
          setShowQrCode={setShowQrCode}
          count={count}
          setCount={setCount}
        />}
      {activeIndex === 1 && <Barcode
        verify2FALoading={verify2FALoading}
        verify2FAHandler={verify2FAHandler}
        signInSuccess={signInSuccess}
      />}
      {activeIndex === 2 && <Code
        otpVerifyLoading={otpVerifyLoading}
        email={email}
        codeScreenHandler={codeScreenHandler}
      />}
      {activeIndex === 3 && <Confirm
        verify2FAHandler={verify2FAHandler}
        verify2FALoading={verify2FALoading}
      />}
    </Login>
  );
};

export default SignIn;
