import React, { useRef, useEffect } from "react";
import styles from "./index.module.sass";
import { widget } from "../../charting_library/charting_library.esm";
import Datafeed from "./dataFeed";

import {
	DISABLED_FEATURES,
	ENABLED_FEATURES,
	TIME_FRAMES,
} from "./chartConfig";

function getLanguageFromURL() {
	const regex = new RegExp("[\\?&]lang=([^&#]*)");
	const results = regex.exec(window.location.search);
	return results === null
		? null
		: decodeURIComponent(results[1].replace(/\+/g, " "));
}

const TVChartContainer = ({ symbol, priceDecimalPrecision, theme }) => {
	const chartContainerRef = useRef(null);
	const defaultProps = {
		symbol: `Bitdenex:${symbol.toLowerCase()}`,
		libraryPath: '/charting_library/'
	};

	useEffect(() => {
		const widgetOptions = {
			symbol: defaultProps.symbol,
			datafeed: Datafeed,
			container: chartContainerRef.current,
			theme: theme,
			library_path: '/charting_library/',
			locale: getLanguageFromURL() || "en",
			fillBackground: true,
			interval: "1D",
			fullscreen: false,
			autosize: true,
			time_frames: TIME_FRAMES,
			enabled_features: ENABLED_FEATURES,
			disabled_features: DISABLED_FEATURES,
			toolbar: false,
			custom_formatters: {
				priceFormatterFactory: (symbolInfo) => {
					if (symbolInfo === null) {
						return null;
					}
					if (symbolInfo.type === 'crypto') {
						return {
							format: (price) => {
								return price.toFixed(priceDecimalPrecision);
							},
						};
					}
					return null;
				},
			},
		};

		const widgetInstance = new widget(widgetOptions);
		window.tvWidget = widgetInstance;

		window.tvWidget.onChartReady(() => {
			window.tvWidget.activeChart().createStudy(
				"Moving Average",
				false,
				false,
				{ "length": 10 },
				{
					"plot.color.0": "#9b59b6",
					"showLabelsOnPriceScale": false,
				},
			);
			window.tvWidget.activeChart().createStudy(
				"Moving Average",
				false,
				false,
				{ length: 30 },
				{
					"plot.color.0": "#d35400",
					"showLabelsOnPriceScale": false
				}
			);
			window.tvWidget.activeChart().createStudy(
				"Moving Average",
				false,
				false,
				{ length: 99 },
				{
					"plot.color.0": "#00cec9",
					"showLabelsOnPriceScale": false
				}
			);

			const studies = window.tvWidget.activeChart().getAllStudies();
			for (let i = 0; i < studies.length; i++) {
				const study = studies[i];
				if (study.name === 'Volume') {
					window.tvWidget.activeChart().removeEntity(study.id);
					break;
				}
			}

		});

		return () => {
			if (window.tvWidget !== null) {
				window.tvWidget.remove();
				window.tvWidget = null;
			}
		};
	}, [symbol, theme]);

	return (
		<>
			<header className={styles.VersionHeader}></header>
			<div ref={chartContainerRef} className={styles.TVChartContainer} />
		</>
	);
};

export default TVChartContainer;
